import React, { useImperativeHandle, forwardRef } from "react";
import * as XLSX from "xlsx"; // Ensure you have the XLSX library installed
import GTM from "../../GTM";
import { absenceType, currencyColumns, leaveType } from "../../constants";

const ExportToCSV = forwardRef(
    (
        {
            data,
            table,
            defaultColumns,
            selectedReport,
            reportLastFetched,
            userEmail,
            portalUserApiError,
            reportingPeriodValue,
            reportingStatusValue,
            selectedCoverageType,
            selectedViewDataType,
            includeHistoricalLeaveValue,
            periodScheduleTypeValue,
            periodStatusValue,
            protectionStatusValue,
            disclaimer,
            organizationsAccess,
            columnVisibility,
            columnOrder,
            ssnDisplayMode,
            organizationName,
            relatedOrganizationNames,
            selectedPolicyDivision,
            selectedReportingGroup
        },
        ref
    ) => {
        const gtm = new GTM(null);
        const csvData = data.map((row) => {
            const rowData = {};

            defaultColumns.forEach((column) => {
                rowData[column.id] = row[column.accessorKey];
            });

            return rowData;
        });
        const dataSets = [{ tableHeader: csvData }];


        const secondPageData = [
            ["Report", selectedReport?.label],
            [],
            ["Date export run", reportLastFetched],
            [],
            ["Organization Name", organizationName],
            [],
            ["Related Organizations", relatedOrganizationNames?.length > 0 ? relatedOrganizationNames : "None"],
            [],
            ["Run by user", userEmail],
            [],

            ["Additional filters / Column filters", "Column filter values"],
        ];

        if (table.getState().columnFilters && table.getState().columnFilters.length > 0) {
            table.getState().columnFilters.forEach((filter) => {
                secondPageData.push([filter.id, filter.value]);
            });
        } else {
            secondPageData.push(["None", "None"])
        }
        secondPageData.push([]);

        secondPageData.push(["Filter", "Filter Values"]);

        if (!portalUserApiError) {
            secondPageData?.push([
                "Reporting period",
                reportingPeriodValue.value,
            ]);

            const periodRange = reportingPeriodValue.label.split("-", 4);
            secondPageData?.push([
                "From date",
                periodRange[0].substring(periodRange[0].length - 11),
            ]);
            secondPageData?.push([
                "To date",
                reportingPeriodValue.label.substring(
                    reportingPeriodValue.label.length - 11
                ).trim(),
            ]);
            secondPageData?.push([
                "Coverage type",
                selectedCoverageType.map((item) => item.value).join(", "),
            ]);
            if (secondPageData && selectedViewDataType !== "Changes") {
                secondPageData?.push([
                    selectedViewDataType === "Payments"
                        ? "Payment status"
                        : "Status",
                    reportingStatusValue.map((item) => item.value).join(", "),
                ]);
            }
            if (secondPageData && selectedViewDataType !== "Leave") {
                secondPageData.push([
                    "Policies and Divisions",
                    selectedPolicyDivision
                        ?.filter((item) => item?.includes("-"))
                        .join(", "),
                ]);
            }

            if (
                secondPageData &&
                (selectedViewDataType === leaveType ||
                    selectedViewDataType === absenceType)
            ) {
                secondPageData.push([
                    "Include Historical Leave",
                    includeHistoricalLeaveValue?.label || "",
                ]);

                secondPageData?.push([
                    "Period Schedule Type",
                    Array.isArray(periodScheduleTypeValue)
                        ? periodScheduleTypeValue
                            .map((item) => item?.value)
                            .join(", ")
                        : "",
                ]);

                secondPageData?.push([
                    "Reporting Group",
                    Array.isArray(selectedReportingGroup)
                        ? selectedReportingGroup
                            .map((item) => item?.value)
                            .join(", ")
                        : "",
                ]);

                const periodStatusString = Array.isArray(periodStatusValue)
                    ? periodStatusValue.map((item) => item?.value).join(", ")
                    : "";

                const protectionStatusString = Array.isArray(
                    protectionStatusValue
                )
                    ? protectionStatusValue
                        .map((item) => item?.value)
                        .join(", ")
                    : "";

                secondPageData?.push(["Period Status", periodStatusString]);
                secondPageData?.push([
                    "Protection Status",
                    protectionStatusString,
                ]);
            }
            secondPageData?.push([" "]);
            secondPageData?.push(["Disclaimer", disclaimer]);
        }
        const sheetNames = ["Data", "Filter criteria"];
        const exportToExcel = () => {
            const data = () => {
                let rowModel = table.getSortedRowModel().rows;

                let tableData = rowModel.map((row) => {
                    return row.getVisibleCells().map((cell) => {
                        let id = cell.column.id;
                        let val = cell.getValue();
                        return { [id]: val };
                    });
                });
                return tableData;
            };

            const filterData = (
                columnVisibility,
                columnOrder,
                ssnDisplayMode
            ) => {
                const keysToInclude = columnOrder.filter(
                    (key) => columnVisibility[key]
                );
                const ssnAccess = organizationsAccess.SSNACCESS;
                const filteredData = [];
                const headerRow = keysToInclude.map((key) => key);
                filteredData.push(headerRow);

                data().forEach((row) => {
                    const filteredRow = [];
                    keysToInclude.forEach((key) => {
                        let cellValue = row.find((x) => x[key]);
                        if (key === "SSN") {
                            if (ssnAccess === "No Access") {
                                filteredRow.push("No Access");
                            } else {
                                switch (ssnDisplayMode) {
                                    case 0:
                                        filteredRow.push("***-**-****");
                                        break;
                                    case 1:
                                        filteredRow.push(
                                            cellValue
                                                ? cellValue[key]?.substr(-4) ||
                                                ""
                                                : ""
                                        );
                                        break;
                                    case 2:
                                        filteredRow.push(
                                            cellValue
                                                ? cellValue[key] || ""
                                                : ""
                                        );
                                        break;
                                    default:
                                        filteredRow.push("");
                                }
                            }
                        } else {
                            let value = cellValue ? cellValue[key] : "";

                            // Remove dollar sign and convert to number if needed
                            if (
                                typeof value === "string" &&
                                value.includes("$")
                            ) {
                                value = Number(value.replace(/[$,]/g, ""));
                            } else if (!isNaN(value) && value !== "") {
                                value = Number(value);
                            }

                            filteredRow.push(value);
                        }
                    });
                    filteredData.push(filteredRow);
                });

                return filteredData;
            };

            const wb = XLSX.utils.book_new();

            dataSets.forEach((dataSet, index) => {
                const filteredData = filterData(
                    columnVisibility,
                    columnOrder,
                    ssnDisplayMode
                );
                const ws = XLSX.utils.aoa_to_sheet(filteredData);

                const columnNames = filteredData[0];

                const colWidths = columnNames.map(() => ({ wpx: 100 }));
                ws["!cols"] = colWidths;

                currencyColumns.forEach((colName) => {
                    const colIndex = columnNames.indexOf(colName);
                    if (colIndex !== -1) {
                        const range = XLSX.utils.decode_range(ws["!ref"]);
                        for (let row = range.s.r + 1; row <= range.e.r; row++) {
                            const cellRef = XLSX.utils.encode_cell({
                                r: row,
                                c: colIndex,
                            });
                            const cell = ws[cellRef];
                            if (cell && !isNaN(cell.v)) {
                                cell.z = "$#,##0.00";
                            }
                        }
                    }
                });

                XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);
            });

            const secondPageWs = XLSX.utils.aoa_to_sheet(secondPageData);

            // Calculate column widths dynamically for the second page data
            const secondPageColumnNames = secondPageData[0]; // First row should be the column names
            const secondPageColWidths = secondPageColumnNames.map(() => {
                let maxLength = 0;
                secondPageData.forEach((row) => {
                    if (row) {
                        const cellValue = row[0]; // Assuming column name is in the first position
                        if (cellValue && cellValue.length > maxLength) {
                            maxLength = cellValue.length;
                        }
                    }
                });
                return { wpx: maxLength * 5 }; // Adjust factor (10) for better scaling
            });

            // Apply the widths to the second page
            secondPageWs["!cols"] = secondPageColWidths;



            XLSX.utils.book_append_sheet(wb, secondPageWs, sheetNames[1]);

            XLSX.writeFile(wb, `${selectedReport?.label}.xlsx`);

            // Tracking event with GTM
            gtm.Event(
                "custom_event",
                "button",
                "click",
                "export to csv",
                "report detail",
                selectedViewDataType,
                "export_report",
                ""
            );
        };

        useImperativeHandle(ref, () => ({
            exportToExcel,
        }));

        return null;
    }
);

export default ExportToCSV;

import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import DataViewIcon from "./DataViewIcons";
import SectionHeading from "../../components/SectionHeading";
import ActionIcon from "../../assets/icons/action-icon.svg";
import Pagination from "../../components/table/Pagination";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import {
    useLazyGetReportDetailsQuery,
    useDeleteReportViewMutation,
    useUpdateReportDetailsMutation,
    useSaveReportViewMutation,
} from "../../slices/saveReportViewSlice";
import {
    useSaveReportViewReminderMutation,
    useUpdateReportViewReminderMutation,
    useDeleteReportViewReminderMutation,
} from "../../slices/saveReportViewReminderSlice";
import { useSelector, useDispatch } from "react-redux";
import ReportDeletePage from "./ReportDeletePage";
import ShareReportDialogBox from "./ShareReportDialogBox";
import ReportSaveNotification from "./ReportSaveNotification";
import Loader from "../../components/ui/Loader";
import SectionSubheading from "../../components/SectionSubheading";
import SavedReportEditDialogBox from "./SavedReportEditDialogBox";
import GTM from "../../GTM";
import useOutsideClick from "../../hooks/useOutsideClick";
import ManageReminderDialogBox from "./ManageReminderDialogBox";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
    DATAVIEWTYPE,
    OWNER_TYPE,
    getSelectedIsfOrganizationIds,
} from "../../utils/helpers";
import {
    absenceType,
    changesReportType,
    dataViewTypeOrder,
    leaveType,
    ownerTypeOrder,
    paymentHistoryType,
} from "../../constants";
import { setReportDeitals } from "../../slices/viewReportSlice";

const Popup = ({
    onClose,
    onEdit,
    onDelete,
    viewData,
    onManageReminder,
    displayManageReminder,
    onShare,
}) => {
    const popupRef = useRef(null);
    const dropdownRef = useRef(null);
    const { user } = useSelector((state) => state.auth);
    const { displayShareReports } = useFlags();
    const isClickedOutside = useOutsideClick(popupRef, dropdownRef);

    useEffect(() => {
        if (isClickedOutside) {
            onClose();
        }
    }, [isClickedOutside, onClose]);

    const sendDeleteEvent = () => {
        onDelete(viewData);
    };

    const sendEditEvent = () => {
        onEdit(viewData);
    };

    const sendManageReminderEvent = () => {
        onManageReminder(viewData);
    };

    const sendShareEvent = () => {
        onShare(viewData);
    };

    return (
        <div ref={dropdownRef}>
            {" "}
            <div className="section-card action-popup" ref={popupRef}>
                {viewData.OwnerType !== "Standard" && (
                    <>
                        <div className="cursor-pointer" onClick={sendEditEvent}>
                            Edit name/description
                        </div>
                    </>
                )}
                {((user.isUserInternal && viewData.OwnerType === "Shared") ||
                    viewData.OwnerType === "Personal") && (
                        <>
                            <div
                                className="cursor-pointer"
                                onClick={sendDeleteEvent}
                            >
                                Delete report
                            </div>
                        </>
                    )}
                {displayManageReminder && !user.isUserInternal && (
                    <div
                        className="cursor-pointer"
                        onClick={sendManageReminderEvent}
                    >
                        Manage reminder
                    </div>
                )}
                {displayShareReports &&
                    viewData.OwnerType === "Personal" &&
                    !viewData.Shared && (
                        <div
                            className="cursor-pointer"
                            onClick={sendShareEvent}
                        >
                            Share
                        </div>
                    )}
            </div>
        </div>
    );
};

const ManageMyView = () => {
    const gtm = new GTM(null);
    const { viewType } = useParams();
    const dispatch = useDispatch();
    const [getReportDetails, { data: savedReportNames }] =
        useLazyGetReportDetailsQuery({});
    const [selectedAction, setSelectedAction] = useState(null);
    const [tableItems, setTableItems] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const [tableData, setTableData] = useState(useLocation().state.data || {});
    const usersPerPage = tableItems ? tableItems : 5;
    const [show, setShow] = useState(false);
    const [selectedView, setSelectedView] = useState(null);
    const [showUpdatePage, setShowUpdatePage] = useState(false);
    const [showManageReminder, setShowManageReminder] = useState(false);
    const { displayManageReminder } = useFlags();

    const [showFrequency, setShowFrequency] = useState(false);
    const [showShareReportDialogBox, setShowShareReportDialogBox] =
        useState(false);
    const {
        selectedOrganizationsReports: userSelectedOrganizationReports,
        selectedOrganizationsIdReports: reportId,
        selectedRelatedOrganizationsForReports: selectedRelatedOrganizations,
    } = useSelector((state) => state.reportOrganization);
    const [showReportSaveNotification, setShowReportSaveNotification] =
        useState(false);
    const [showReportUpdatedNotification, setShowReportUpdatedNotification] =
        useState(false);
    const [showSetReminderNotification, setShowSetReminderNotification] =
        useState(false);
    const [showDeleteReminderNotification, setShowDeleteReminderNotification] =
        useState(false);
    const [showUpdateReminderNotification, setShowUpdateReminderNotification] =
        useState(false);
    const [showShareReportNotification, setShowShareReportNotification] =
        useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [changedViewName, setChangedViewName] = useState();
    const [changedViewDescription, setChangedViewDescription] = useState();
    const [updateReportViewMutation] = useUpdateReportDetailsMutation();
    const [actionType, setActiontype] = useState();
    const [manageReminderReportName, setManageReminderReportName] = useState();
    const [manageSubscription, setManageSubscription] = useState();
    const [selectedFrequencyPattern, setSelectedFrequencyPattern] = useState();
    const [selectedReminderCheck, setSelectedReminderCheck] = useState(false);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
    const [daysOfWeeks, setDaysOfWeeks] = useState([]);
    const [daysOfMonth, setDaysOfMonth] = useState([]);
    let disableSaveButton = true;
    const [selectedRecurrencePattern, setSelectedRecurrencePattern] =
        useState();
    const [saveReportViewReminderMutation] =
        useSaveReportViewReminderMutation();
    const [updateReportViewReminderMutation] =
        useUpdateReportViewReminderMutation();
    const [deleteReportViewReminderMutation] =
        useDeleteReportViewReminderMutation();
    const [deleteReportViewMutation] = useDeleteReportViewMutation();
    // setting SubscriptionInfo
    let recurrancePattern = null;
    let daysOfTheWeek = null;
    let monthsOfTheYear = null;
    let dayOfTheMonth = null;

    const navigate = useNavigate();

    const { selectedIsfOrganizationIds, organizationGroupId } =
        getSelectedIsfOrganizationIds(
            userSelectedOrganizationReports,
            reportId,
            selectedRelatedOrganizations
        );

    const relatedOrgNames = Array.from(selectedRelatedOrganizations).map(
        (relatedOrg) => relatedOrg.label
    );
    const sortedRelatedOrgCSV = relatedOrgNames.sort().join(",");
    const formattedRelatedOrgCSV =
        sortedRelatedOrgCSV.length > 30
            ? sortedRelatedOrgCSV.substring(0, 30) + "..."
            : sortedRelatedOrgCSV;
    const [saveReportViewMutation] = useSaveReportViewMutation();
    const { user } = useSelector((state) => state.auth);
    const reportDetailsParams = {
        isfUserId: user.userId,
        dataViewType: DATAVIEWTYPE,
        selectedIsfOrganizationIds: selectedIsfOrganizationIds,
    };

    if (organizationGroupId) {
        reportDetailsParams.organizationGroupId = organizationGroupId;
    }

    useEffect(() => {
        if (tableData.length === 0) {
            getReportDetails(reportDetailsParams);
        }
    }, []);

    useEffect(() => {
        if (savedReportNames) {
            dispatch(setReportDeitals(savedReportNames));
        }
    }, [savedReportNames]);

    useEffect(() => {
        if (savedReportNames) {
            setTableData(savedReportNames);
        }
    }, [savedReportNames]);

    const getPageCount = () => {
        return Math.ceil(tableData.length / usersPerPage);
    };
    const handleItemsPerPageChange = (e) => {
        setTableItems(e);
    };
    const pagesVisited = pageNumber * usersPerPage;
    const changePage = (selected) => {
        setPageNumber(selected);
    };

    const handelGtmTags = (
        category,
        action,
        label,
        attribute1,
        attribute2,
        attribute3,
        attribute4
    ) => {
        gtm.Event(
            "manage my reports",
            category ? category : "button",
            action ? action : "click",
            label,
            attribute1 ? attribute1 : "manage my reports",
            attribute2 ? attribute2 : "action",
            attribute3 ? attribute3 : "field_interaction",
            attribute4 ? attribute4 : ""
        );
    };

    const { config } = useSelector((state) => state.app);

    const page_referrer_title = null;
    const { id } = useParams();

    useEffect(() => {
        const userName = "";
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal ? "internal" : "external";

        gtm.PageView(
            "manage my reports",
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL}${page_referrer_title}`,
            page_referrer_title,
            id
        );
    }, [20000]);

    const handleActionClick = (index) => {
        setSelectedAction(index);
        handelGtmTags("button", "click", "ellipsis");
    };

    const handleEdit = (item) => {
        setSelectedView(item);
        setSelectedAction(null);
        setShowUpdatePage(true);

        setChangedViewName(item.Name);
        setChangedViewDescription(item.Description);
        handelGtmTags(
            "button",
            "click",
            "ellipsis",
            "manage my reports",
            "action",
            "field_interaction",
            "edit name/description"
        );
    };

    const handleDelete = (item) => {
        setShow(true);
        setSelectedView(item);
        setSelectedAction(null);
        handelGtmTags(
            "button",
            "click",
            "ellipsis",
            "manage my reports",
            "action",
            "field_interaction",
            "delete report"
        );
    };

    const handleShareReport = (item) => {
        setSelectedView(item);
        setShowShareReportDialogBox(true);
        handelGtmTags(
            "button",
            "click",
            "ellipsis",
            "manage my reports",
            "action",
            "field_interaction",
            "share"
        );
    };

    const handleManageReminder = (item) => {
        setShowManageReminder(true);
        setManageReminderReportName(item.Name);
        if (item.SubscriptionInfo) {
            setManageSubscription(item.SubscriptionInfo);
            setShowFrequency(true);
            setSelectedReminderCheck(true);
            setSelectedFrequencyPattern(item.SubscriptionInfo.Frequency);
            if (item.SubscriptionInfo.Frequency === "Daily") {
                setSelectedRecurrencePattern(
                    item.SubscriptionInfo.RecurrancePattern === "Daily"
                        ? "Everyday"
                        : "Every weekday"
                );
            } else if (item.SubscriptionInfo.Frequency === "Weekly") {
                setSelectedRecurrencePattern("Weekly");
            } else if (item.SubscriptionInfo.Frequency === "Monthly") {
                const recurrancePatterns =
                    item.SubscriptionInfo.RecurrancePattern === "Monthly"
                        ? "everyMonth"
                        : "selectMonths";
                const selectedItems = {
                    selectedDates: item.SubscriptionInfo.DayOfTheMonth,
                    selectedMonths: item.SubscriptionInfo.MonthsOfTheYear,
                    selectedMonthly: recurrancePatterns,
                };
                setDaysOfMonth(selectedItems);
                setSelectedRecurrencePattern(
                    item.SubscriptionInfo.RecurrancePattern === "Monthly"
                        ? "everyMonth"
                        : "selectMonths"
                );
            }
            setSelectedSubscriptionId(item.SubscriptionInfo.SubscriptionId);
            disableSaveButton = false;
        }
        if (!item.SubscriptionInfo) {
            setSelectedReminderCheck(false);
        }
        setSelectedView(item);
        handelGtmTags(
            "button",
            "click",
            "ellipsis",
            "manage my reports",
            "action",
            "field_interaction",
            "manage reminder"
        );
    };

    const handleReportViewDelete = () => {
        setActiontype("Delete");
        setShowLoader(true);
        deleteReportViewMutation({
            dataViewType: {
                viewIds: [selectedView.ViewId],
            },
        }).then(() => {
            getReportDetails(reportDetailsParams).then(() => {
                setShowLoader(false);
                setShowReportSaveNotification(true);
                setTimeout(() => {
                    setShowReportSaveNotification(false);
                }, 6000);
            });
        });
        setShow(false);
    };

    const handleReportViewCancelDelete = () => {
        setShow(false);
        setShowShareReportDialogBox(false);
        handelGtmTags(
            "button",
            "click",
            "cancel",
            "manage my views",
            "share modal",
            "field_interaction",
            "undefined"
        );
    };

    const handleReportSavedNotificationClose = () => {
        setShowReportSaveNotification(false);
    };

    const handleReportUpdatedNotificationClose = () => {
        setShowReportUpdatedNotification(false);
    };

    const handleSetReminderNotificationClose = () => {
        setShowSetReminderNotification(false);
    };

    const handleDeleteReminderNotificationClose = () => {
        setShowDeleteReminderNotification(false);
    };

    const handleUpdateReminderNotificationClose = () => {
        setShowUpdateReminderNotification(false);
    };
    const handleShareReportNotificationClose = () => {
        setShowShareReportNotification(false);
    };

    const handleReportViewDontSave = () => {
        setShow(false);
        setShowUpdatePage(false);
        setShowManageReminder(false);
        setShowFrequency(false);
        setSelectedRecurrencePattern(null);
        setDaysOfMonth([]);
        setSelectedReminderCheck(false);
        setSelectedFrequencyPattern(null);
        setManageSubscription(undefined);
        setSelectedSubscriptionId(null);
        handelGtmTags(
            "button",
            "click",
            "cancel",
            "manage my views",
            "subscribe modal",
            "field_interaction",
            "undefined"
        );
    };

    const handleReportViewUpdate = (viewNameValue, descriptionValue) => {
        setActiontype("Update");
        setShowUpdatePage(false);
        setShowLoader(true);
        updateReportViewMutation({
            dataToUpdate: {
                IsfUserId: user.userId,
                OwnerType: "Personal",
                SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                DataViewType: selectedView.DataViewType,
                IsEnabled: true,
                DataViewInfo: {
                    Name: viewNameValue ? viewNameValue : changedViewName,
                    Description: descriptionValue
                        ? descriptionValue
                        : changedViewDescription,
                    Parameters: {
                        CoverageType: selectedView.CoverageType,

                        EffectiveEndDt: selectedView.EffectiveEndDate,
                        EffectiveStartDt: selectedView.EffectiveStartDate,
                        GroupAgreementDivisions:
                            selectedView.DataViewType !== leaveType ||
                                selectedView.DataViewType !== changesReportType
                                ? selectedView.GroupAgreementDivisions
                                : null,
                        IncludeHistoricalLeave:
                            selectedView.IncludeHistoricalLeave,
                        PaymentStatuses: selectedView.PaymentStatuses,
                        PeriodScheduleTypes: selectedView.PeriodScheduleTypes,
                        PeriodStatuses: selectedView.PeriodStatuses,
                        ProductGroupCds: ["STD", "SI-STD"],
                        ProtectionStatuses: selectedView.ProtectionStatuses,
                        ReportingGroups:
                            selectedView.DataViewType === absenceType ||
                                selectedView.DataViewType === leaveType ||
                                selectedView.DataViewType === changesReportType
                                ? selectedView.ReportingGroups
                                : null,
                        ReportingPeriodMethod: null,
                        Status: selectedView.Status,
                    },
                    TableDisplayInformation: {
                        SortOrder: selectedView.SortOrder,

                        ColumnInformation: selectedView.ColumnInformation,
                    },
                },
            },
            viewId: selectedView.ViewId,
        }).then(() => {
            getReportDetails(reportDetailsParams).then(() => {
                setShowLoader(false);
                setShowReportUpdatedNotification(true);
                setTimeout(() => {
                    setShowReportUpdatedNotification(false);
                }, 6000);
            });
        });
    };

    const handleViewNameChange = (item) => {
        setChangedViewName(item);
    };

    const handleDescriptionChange = (item) => {
        setChangedViewDescription(item);
    };

    const handleReminderCheck = (item) => {
        setShowFrequency(item);
        if (!item) {
            setSelectedReminderCheck(false);
            handelGtmTags(
                "checkbox",
                "uncheck",
                "subscribe to receive notifiications/emails",
                "manage my views",
                "subscribe modal",
                "field_interaction",
                "undefined"
            );
        } else if (item) {
            setSelectedReminderCheck(true);
            handelGtmTags(
                "checkbox",
                "check",
                "subscribe to receive notifiications/emails",
                "manage my views",
                "subscribe modal",
                "field_interaction",
                "undefined"
            );
        }
    };

    const handleFrequencyPatternClick = (item) => {
        setSelectedFrequencyPattern(item);
        handelGtmTags(
            "button",
            "click",
            "frequency",
            "manage my views",
            "subscribe modal",
            "field_interaction",
            item
        );
    };

    const handleRecurrencePatternClick = (item) => {
        setSelectedRecurrencePattern(item);
        handelGtmTags(
            "button",
            "click",
            "daily recurrence pattern",
            "manage my views",
            "subscribe modal",
            "field_interaction",
            item
        );
    };

    const handleDaysOfTheWeek = (weeks) => {
        setDaysOfWeeks(weeks);
        handelGtmTags(
            "button",
            "click",
            "weekly recurrence days",
            "manage my views",
            "subscribe modal",
            "field_interaction",
            weeks
        );
    };

    const handleMonthly = ({
        selectedDates,
        selectedMonths,
        selectedMonthly,
    }) => {
        setDaysOfMonth({
            selectedDates: selectedDates,
            selectedMonths: selectedMonths,
            selectedMonthly: selectedMonthly,
        });
        if (selectedMonthly === "everyMonth") {
            handelGtmTags(
                "button",
                "click",
                "monthly recurrence pattern",
                "manage my views",
                "subscribe modal",
                "field_interaction",
                selectedMonthly
            );
        } else if (selectedMonthly === "selectMonths") {
            handelGtmTags(
                "button",
                "click",
                "monthly recurrence pattern",
                "manage my views",
                "subscribe modal",
                "field_interaction",
                selectedMonthly
            );
        }
        if (selectedDates) {
            handelGtmTags(
                "button",
                "click",
                "monthly recurrence date",
                "manage my views",
                "subscribe modal",
                "field_interaction",
                selectedDates
            );
        }
        if (selectedMonths.length > 0) {
            handelGtmTags(
                "button",
                "click",
                "monthly recurrence month",
                "manage my views",
                "subscribe modal",
                "field_interaction",
                selectedMonths
            );
        }
    };

    const getReportDetailsForRefresh = () => {
        getReportDetails(reportDetailsParams);
    };
    if (selectedFrequencyPattern === "Daily") {
        recurrancePattern =
            selectedRecurrencePattern === "Everyday" ? "Daily" : "WorkWeek";
        dayOfTheMonth = -1;
        if (
            selectedRecurrencePattern === "Everyday" ||
            selectedRecurrencePattern === "Every weekday"
        ) {
            disableSaveButton = false;
        } else {
            disableSaveButton = true;
        }
    } else if (selectedFrequencyPattern === "Weekly") {
        recurrancePattern = null;
        daysOfTheWeek = daysOfWeeks;
        dayOfTheMonth = -1;
        disableSaveButton = daysOfWeeks.length > 0 ? false : true;
    } else if (selectedFrequencyPattern === "Monthly") {
        recurrancePattern =
            daysOfMonth.selectedMonths?.length > 0
                ? "SelectMonths"
                : selectedFrequencyPattern;
        daysOfTheWeek = null;
        monthsOfTheYear =
            daysOfMonth.selectedMonths?.length > 0
                ? daysOfMonth.selectedMonths
                : null;
        dayOfTheMonth = daysOfMonth.selectedDates;

        if (daysOfMonth.selectedMonthly === "selectMonths") {
            if (
                (daysOfMonth.selectedDates !== undefined &&
                    daysOfMonth.selectedMonths.length > 0) ||
                (manageSubscription?.DayOfTheMonth !== undefined &&
                    manageSubscription?.MonthsOfTheYear.length < 12)
            ) {
                disableSaveButton = false;
            } else {
                disableSaveButton = true;
            }
        }

        if (daysOfMonth.selectedMonthly === "everyMonth") {
            if (
                daysOfMonth.selectedDates !== undefined ||
                (manageSubscription?.DayOfTheMonth !== undefined &&
                    manageSubscription.RecurrancePattern === "Monthly")
            ) {
                disableSaveButton = false;
            } else {
                disableSaveButton = true;
            }
        }
    }

    const handleSetReminder = () => {
        setShowManageReminder(false);
        setShowLoader(true);

        if (selectedSubscriptionId !== null && !selectedReminderCheck) {
            setActiontype("Delete");
            deleteReportViewReminderMutation({
                dataToUpdate: {
                    SubscriptionId: selectedSubscriptionId,
                },
                viewId: selectedView.ViewId,
            })
                .unwrap()
                .then(() => {
                    getReportDetailsForRefresh();
                    setShowLoader(false);
                    setSelectedRecurrencePattern(undefined);
                    setShowFrequency(false);
                    disableSaveButton = false;
                    setDaysOfMonth([]);
                    setShowDeleteReminderNotification(true);
                    setSelectedFrequencyPattern(undefined);
                    setManageSubscription(undefined);
                    setSelectedSubscriptionId(null);
                    setTimeout(() => {
                        setShowDeleteReminderNotification(false);
                    }, 6000);
                })
                .catch((e) => {
                    setShowLoader(false);
                    setSelectedRecurrencePattern("");
                    setShowFrequency(false);
                    disableSaveButton = true;
                });
        } else if (selectedSubscriptionId !== null && selectedReminderCheck) {
            setActiontype("Update");
            updateReportViewReminderMutation({
                dataToUpdate: {
                    Frequency: selectedFrequencyPattern,
                    RecurrancePattern: recurrancePattern,
                    DaysOfTheWeek: daysOfTheWeek,
                    MonthsOfTheYear: monthsOfTheYear,
                    DayOfTheMonth: dayOfTheMonth,
                },
                viewId: selectedView.ViewId,
                SubscriptionId: selectedSubscriptionId,
            })
                .unwrap()
                .then(() => {
                    getReportDetailsForRefresh();
                    setShowLoader(false);
                    setSelectedRecurrencePattern(undefined);
                    setShowFrequency(false);
                    disableSaveButton = false;
                    setDaysOfMonth([]);
                    setShowUpdateReminderNotification(true);
                    setSelectedFrequencyPattern(undefined);
                    setManageSubscription(undefined);
                    setSelectedSubscriptionId(null);
                    setTimeout(() => {
                        setShowUpdateReminderNotification(false);
                    }, 6000);
                })
                .catch((e) => {
                    setShowLoader(false);
                    setSelectedRecurrencePattern("");
                    setShowFrequency(false);
                    disableSaveButton = true;
                    setDaysOfMonth([]);
                    setSelectedFrequencyPattern(undefined);
                });
        } else if (!selectedSubscriptionId) {
            setActiontype("Set");
            saveReportViewReminderMutation({
                dataToUpdate: {
                    IsfUserId: user.userId,
                    SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                    EmailAddress: user.userEmail,
                    InternalUser: user.isUserInternal,
                    SubscriptionInfo: {
                        Frequency: selectedFrequencyPattern,
                        RecurrancePattern: recurrancePattern,
                        DaysOfTheWeek: daysOfTheWeek,
                        MonthsOfTheYear: monthsOfTheYear,
                        DayOfTheMonth: dayOfTheMonth,
                    },
                },
                viewId: selectedView.ViewId,
            })
                .unwrap()
                .then(() => {
                    getReportDetailsForRefresh();
                    setShowLoader(false);
                    setSelectedRecurrencePattern(undefined);
                    setShowFrequency(false);
                    disableSaveButton = false;
                    setDaysOfMonth([]);
                    setShowSetReminderNotification(true);
                    setSelectedFrequencyPattern(undefined);
                    setManageSubscription(undefined);
                    setSelectedSubscriptionId(null);
                    setTimeout(() => {
                        setShowSetReminderNotification(false);
                    }, 6000);
                })
                .catch((e) => {
                    setShowLoader(false);
                    setSelectedRecurrencePattern("");
                    setShowFrequency(false);
                    disableSaveButton = true;
                    setDaysOfMonth([]);
                    setSelectedFrequencyPattern(undefined);
                });
        }
    };

    const getLoaderText = () => {
        switch (actionType) {
            case "Update":
                return "Updating";
            case "Delete":
                return "Deleting";
            case "Set":
                return "Saving";
            case "Share":
                return "Sharing";
            default:
                return "";
        }
    };

    const onShareReportClick = () => {
        setShowShareReportDialogBox(false);
        setActiontype("Share");
        setShowLoader(true);
        saveReportViewMutation({
            dataToUpdate: {
                IsfUserId: user.userId,
                OwnerType: "Shared",
                SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                DataViewType: selectedView.DataViewType,
                OriginalViewId: selectedView.ViewId,
                DataViewInfo: {
                    Name: selectedView.Name,
                    Description: selectedView.Description,
                    Parameters: {
                        CoverageType: selectedView.CoverageType,
                        EffectiveEndDt: selectedView.EffectiveEndDate,
                        EffectiveStartDt: selectedView.EffectiveStartDate,
                        GroupAgreementDivisions:
                            selectedView.GroupAgreementDivisions === undefined
                                ? null
                                : selectedView.GroupAgreementDivisions,
                        IncludeHistoricalLeave:
                            selectedView.IncludeHistoricalLeave,
                        PaymentStatuses:
                            selectedView.PaymentStatuses === undefined
                                ? null
                                : selectedView.PaymentStatuses,
                        PeriodScheduleTypes: selectedView.PeriodScheduleTypes,
                        PeriodStatuses:
                            selectedView.PeriodStatuses === undefined
                                ? null
                                : selectedView.PeriodStatuses,
                        ProductGroupCds: selectedView.ProductGroupCds,
                        ProtectionStatuses:
                            selectedView.ProtectionStatuses === undefined
                                ? null
                                : selectedView.ProtectionStatuses,
                        ReportingGroups:
                            selectedView.ReportingGroups === undefined
                                ? null
                                : selectedView.ReportingGroups,
                        ReportingPeriodMethod:
                            selectedView.ReportingPeriodMethod === undefined
                                ? null
                                : selectedView.ReportingPeriodMethod,
                        Status: selectedView.Status,
                    },
                    TableDisplayInformation: {
                        SortOrder:
                            selectedView.SortOrder === undefined
                                ? null
                                : selectedView.SortOrder,
                        ColumnInformation: selectedView.ColumnInformation,
                    },
                },
            },
        }).then(() => {
            getReportDetails(reportDetailsParams).then(() => {
                setShowLoader(false);
                setShowShareReportNotification(true);
                setTimeout(() => {
                    setShowShareReportNotification(false);
                }, 6000);
            });
        });
        handelGtmTags(
            "button",
            "click",
            "share",
            "manage my views",
            "share modal",
            "share_view",
            "undefined"
        );
    };

    const sortedTableData = [...tableData].sort((a, b) => {
        const ownerTypeComparison =
            ownerTypeOrder.indexOf(a.OwnerType) -
            ownerTypeOrder.indexOf(b.OwnerType);
        if (ownerTypeComparison !== 0) {
            return ownerTypeComparison;
        } else {
            return (
                dataViewTypeOrder.indexOf(a.DataViewType) -
                dataViewTypeOrder.indexOf(b.DataViewType)
            );
        }
    });
    const handleReportNameClick = (selectedName, selectedReportData) => {
        const selectedReportTypeData = tableData
            .filter((obj) =>
                obj.DataViewType.includes(selectedReportData.DataViewType)
            )
            .map((obj) => obj);
        navigate(
            `/reportingcapabilities/${selectedReportData.DataViewType === "Payments"
                ? "PaymentHistory"
                : selectedReportData.DataViewType
            }`,
            {
                state: {
                    selectedReport: selectedReportData,
                    selectedReportTypeData: selectedReportTypeData,
                },
            }
        );
    };

    const getReportAction = (item) => {
        if (user.isUserInternal && item.OwnerType !== OWNER_TYPE) return true;
        if (!user.isUserInternal) return true;
    };

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        name: "Reports",
                        path: "/reportingcapabilities",
                    },
                    ...(viewType
                        ? [
                            {
                                name:
                                    viewType === paymentHistoryType
                                        ? "Payment history"
                                        : viewType,
                                path: `/reportingcapabilities/${viewType}`,
                            },
                        ]
                        : []),
                    {
                        name: "Manage my reports",
                    },
                ]}
            />

            <div className="section-card px-0  ">
                <div className="d-flex align-items-baseline px-24p">
                    <SectionHeading
                        heading="Manage my reports |"
                        className="mr-10p"
                    />
                    <SectionSubheading
                        className="text-semibold sub-heading-disabled mr-10p"
                        subheading={
                            userSelectedOrganizationReports[reportId]
                                .organization.ORGANIZATIONGROUPNAME
                                ? userSelectedOrganizationReports[reportId]
                                    .organization.ORGANIZATIONGROUPNAME
                                : userSelectedOrganizationReports[reportId]
                                    .organization.ORGNIZATIONNAME
                        }
                    ></SectionSubheading>
                    {userSelectedOrganizationReports[reportId].organization
                        .ORGANIZATIONGROUPNAME ? (
                        <>
                            <SectionHeading heading="|" className="mr-10p" />
                            <div className="sub-heading-disabled-related">
                                <div className="customtooltip">
                                    {"Related orgs: " + formattedRelatedOrgCSV}
                                    <div style={{ marginTop: "16px" }}>
                                        <span className="customTooltipText">
                                            {relatedOrgNames.map(
                                                (relatedOrg) => (
                                                    <>
                                                        <span>
                                                            {relatedOrg}
                                                        </span>
                                                        <br />
                                                    </>
                                                )
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {showLoader ? (
                    <Loader text={getLoaderText()} />
                ) : (
                    <>
                        <table className="frequently-used-data">
                            <thead>
                                <tr>
                                    <th
                                        className="py-8p"
                                        style={{ paddingLeft: 24 }}
                                    >
                                        Report name
                                    </th>
                                    <th className="py-8p">Type</th>
                                    <th className="py-8p">Description</th>
                                    {displayManageReminder &&
                                        !user.isUserInternal && (
                                            <th className="py-8p">Reminder</th>
                                        )}
                                    <th className="py-8p">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {sortedTableData
                                    .slice(
                                        pagesVisited,
                                        parseInt(pagesVisited) +
                                        parseInt(usersPerPage)
                                    )
                                    .map((item, index) => (
                                        <tr
                                            key={index}
                                            className={
                                                index % 2 === 0 ? "odd" : "even"
                                            }
                                        >
                                            <td
                                                className="py-7p"
                                                style={{
                                                    paddingLeft: 24,
                                                }}
                                            >
                                                <span>
                                                    <DataViewIcon
                                                        dataViewType={item}
                                                        onClick={
                                                            handleReportNameClick
                                                        }
                                                    />
                                                </span>
                                            </td>
                                            <td className="py-7p">
                                                {item.OwnerType}
                                            </td>
                                            <td className="py-7p">
                                                {item.Description}
                                            </td>
                                            {!user.isUserInternal && (
                                                <td
                                                    className="py-7p"
                                                    style={{ color: "#037CB7" }}
                                                >
                                                    {(() => {
                                                        const subscriptionInfo =
                                                            item.SubscriptionInfo;
                                                        const frequency =
                                                            subscriptionInfo?.Frequency;

                                                        if (
                                                            subscriptionInfo ===
                                                            null ||
                                                            subscriptionInfo ===
                                                            undefined
                                                        ) {
                                                            return (
                                                                <span>
                                                                    &#8212;
                                                                </span>
                                                            );
                                                        }

                                                        if (
                                                            frequency ===
                                                            "Monthly"
                                                        ) {
                                                            return (
                                                                <span className="d-flex align-items-center gap-1">
                                                                    <i
                                                                        className="fa-solid fa-calendar-days"
                                                                        style={{
                                                                            paddingRight: 5,
                                                                        }}
                                                                    ></i>{" "}
                                                                    {frequency}
                                                                </span>
                                                            );
                                                        } else if (
                                                            frequency ===
                                                            "Weekly"
                                                        ) {
                                                            return (
                                                                <span className="d-flex align-items-center gap-1">
                                                                    <i className="fa-solid fa-calendar-week"></i>
                                                                    {frequency}
                                                                </span>
                                                            );
                                                        } else if (
                                                            frequency ===
                                                            "Daily"
                                                        ) {
                                                            return (
                                                                <span className="d-flex align-items-center gap-1">
                                                                    <i className="fa-solid fa-calendar-day"></i>
                                                                    {frequency}
                                                                </span>
                                                            );
                                                        } else {
                                                            return "";
                                                        }
                                                    })()}
                                                </td>
                                            )}

                                            <td
                                                style={{
                                                    paddingLeft: 24,
                                                    paddingTop: 7,
                                                    paddingBottom: 7,
                                                }}
                                            >
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        handleActionClick(index)
                                                    }
                                                >
                                                    {getReportAction(item) && (
                                                        <img
                                                            src={ActionIcon}
                                                            alt="action"
                                                        />
                                                    )}
                                                </span>
                                                {selectedAction === index && (
                                                    <Popup
                                                        onClose={() =>
                                                            setSelectedAction(
                                                                null
                                                            )
                                                        }
                                                        onEdit={handleEdit}
                                                        onDelete={handleDelete}
                                                        viewData={item}
                                                        onManageReminder={
                                                            handleManageReminder
                                                        }
                                                        onShare={
                                                            handleShareReport
                                                        }
                                                        displayManageReminder={
                                                            displayManageReminder
                                                        }
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {tableData.length > 50 && (
                            <div className="pt-20p">
                                <Pagination
                                    pageCount={getPageCount()}
                                    gotoPage={changePage}
                                    setPageSize={handleItemsPerPageChange}
                                    pageSize={tableItems ?? 50}
                                    options={[50, 100]}
                                    attribute1="report"
                                    pageName="manage my report"
                                    attribute4=""
                                    tabIndex={0}
                                    setPageNumberOnRefresh={pageNumber}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            {selectedView && (
                <ReportDeletePage
                    onDontSave={handleReportViewCancelDelete}
                    onSave={handleReportViewDelete}
                    onShow={show}
                    selectedView={selectedView}
                />
            )}
            <SavedReportEditDialogBox
                onShow={showUpdatePage}
                onDontSave={handleReportViewDontSave}
                onSave={handleReportViewUpdate}
                reportName={changedViewName}
                reportDescription={changedViewDescription}
                onViewNameChange={handleViewNameChange}
                onDescriptionChange={handleDescriptionChange}
                selectedView={selectedView}
            />
            <ManageReminderDialogBox
                onShow={showManageReminder}
                onDontSave={handleReportViewDontSave}
                reportName={manageReminderReportName}
                onReminderCheck={handleReminderCheck}
                showFrequency={showFrequency}
                onFrequencyPatternClick={handleFrequencyPatternClick}
                showFrequencyPattern={selectedFrequencyPattern}
                showRecurrencePattern={selectedRecurrencePattern}
                onSave={handleSetReminder}
                onRecurrencePatternClick={handleRecurrencePatternClick}
                handleDaysOfTheWeek={handleDaysOfTheWeek}
                handleMonthly={handleMonthly}
                disableSaveButton={disableSaveButton}
                subscriptionInfo={manageSubscription}
                reminderCheck={selectedReminderCheck}
                daysOfMonth={daysOfMonth}
            />
            <ShareReportDialogBox
                onDontSave={handleReportViewCancelDelete}
                onSave={onShareReportClick}
                onShow={showShareReportDialogBox}
                selectedView={selectedView}
            />
            {showReportSaveNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleReportSavedNotificationClose}
                    msgIndicator="DELETE"
                    selectedView={selectedView}
                ></ReportSaveNotification>
            )}

            {showReportUpdatedNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleReportUpdatedNotificationClose}
                    msgIndicator="manageViewUpdate"
                ></ReportSaveNotification>
            )}
            {showSetReminderNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleSetReminderNotificationClose}
                    msgIndicator="SET"
                ></ReportSaveNotification>
            )}
            {showDeleteReminderNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleDeleteReminderNotificationClose}
                    msgIndicator="Del"
                ></ReportSaveNotification>
            )}
            {showUpdateReminderNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleUpdateReminderNotificationClose}
                    msgIndicator="PUT"
                ></ReportSaveNotification>
            )}
            {showShareReportNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleShareReportNotificationClose}
                    msgIndicator="manageViewUpdate"
                    titleInformation="reportShared"
                ></ReportSaveNotification>
            )}
        </>
    );
};

export default ManageMyView;

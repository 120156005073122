import React from "react";

function ScrollToTopIcon() {

    return (
        <div
            className="scroll-to-top d-none"
            id="scroll-to-top"
            tabIndex={0}
            role="button"
        >
            <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }}
            >
                <i className="fa-solid fa-arrow-up"></i>
                <span className="fs-sm d-block mt-10p">Back To Top</span>
            </div>
        </div>
    );
}

export default ScrollToTopIcon;
